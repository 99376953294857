import React, { useState } from "react";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

// swiper/css/bundle

interface CustomSwiperProps {
  elements: (JSX.Element | undefined)[] | undefined;
}

export default function SwiperForProModal({ elements }: CustomSwiperProps) {
  const [swiper, setSwiper] = useState<any>(null);

  const pagination = {
    clickable: true,
  };

  let swiperProps = {
    direction: "horizontal" as const,
    pagination: pagination,
    modules: [Pagination], // Make sure Navigation is imported correctly
    breakpoints: {
      1280: {
        slidesPerView: 3,
      },
      840: {
        slidesPerView: 2,
      },
      0: {
        slidesPerView: 1,
      },
    },
    onSwiper: setSwiper,
  };

  return (
    <Swiper {...swiperProps} className="-mt-12 !flex !w-full flex-wrap">
      {elements?.map((element, index) => (
        <SwiperSlide key={index} className="!flex min-h-max w-full">
          <div className="w-full max-h-96 sm:max-h-max overflow-auto p-2">
            {element}
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
